import React from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <MDBFooter className='text-center text-lg-start text-muted dark' style={{ marginTop: '40px' }}>
      <MDBContainer className='text-center text-md-start mt-5'>
        <MDBRow className='mt-3'>
          <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>
              <MDBIcon icon="gem" className="" />
              Generate Invoices
            </h6>
            <p>
              Automate invoicing for a streamlined and error-free billing process. Simplify financial management with our Invoice Generator."
            </p>
          </MDBCol>

          <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
            <p>
              <a href='/' className='text-reset'>
                Home
              </a>
            </p>
            <p>
              <a href='/settings' className='text-reset'>
                Settings
              </a>
            </p>
            <p>
              <a href='/invoices' className='text-reset'>
                Invoices
              </a>
            </p>
          </MDBCol>


          <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
            <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
            <p>
              <a href='/contact' className='text-reset'>
                Contact Us
              </a>
            </p>
            <p>
              <a href='/terms' className='text-reset'>
                Terms & Conditions
              </a>
            </p>
            <p>
              <a href='/privacy' className='text-reset'>
                Privacy Policy
              </a>
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © {currentYear} Copyright:
        <a className='text-reset fw-bold' href='https://generate-invoices.com/'>
          Generate-Invoices.com
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;