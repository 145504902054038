import React from 'react'

const contact = () => {
  return (
    <div className='container mt-4 p-3'>
      <h1 className='mb-3'>Contact</h1>
      <p>
      We value your feedback and are here to assist you. If you have any questions, concerns, or feedback regarding our services or products, please don't hesitate to reach out. You can contact us via email at info@generate-invoices.com or by the Social Media below. Our team will do their best to respond to your inquiry promptly. Thank you for choosing Generate Invoices!
    </p>
    
   
    </div>
  )
}

export default contact
